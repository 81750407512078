var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.downloadable
    ? _c(
        "RtbButton",
        { attrs: { size: "sm", capitalize: "" }, on: { click: _vm.download } },
        [
          _c("SvgIcon", {
            attrs: { name: "cloud-download-regular", size: "small" },
          }),
          _vm._v(" Download PDF "),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }