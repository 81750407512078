class File {
  static download(data, filename) {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(data, filename)
    } else {
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(data)
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  /**
   *
   * @param {{multiple: boolean; accept: string}} attributes
   * @returns {Promise<unknown>}
   */
  static select(attributes) {
    return new Promise(resolve => {
      let input = document.createElement("input")
      input.type = "file"
      Object.entries(attributes).forEach(([attribute, value]) => {
        input.setAttribute(attribute, value)
      })
      input.onchange = function (event) {
        resolve(event.srcElement.files)
      }
      input.click()
    })
  }
}

export default File
