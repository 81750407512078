







import { defineComponent, computed } from "@vue/composition-api"
import { RtbButton } from "@/components/ui"
import File from "@/services/file.service"
import useStore from "@/use/useStore"

export default defineComponent({
  components: {
    RtbButton
  },
  setup() {
    const { store } = useStore()
    const mission = computed(() => store.getters.getCurrentMission)
    const pdfUrl = computed(() => mission.value?.documentUrl)
    const downloadable = computed(
      () => mission.value?.documentDownloadable && pdfUrl.value
    )
    const download = async () => {
      File.download(await (await fetch(pdfUrl.value)).blob(), "document.pdf")
    }

    return { download, downloadable, mission, pdfUrl }
  }
})
